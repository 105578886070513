<template>
    <v-container>
        <v-card
            elevation="2"
            outlined 
            class="blue lighten-4">
            <v-card-title class="pb-0">
                <h4 class="primary--text">Add Incident Report</h4>
            </v-card-title>
            <v-card-text>
                <p style="margin-bottom:0px; font-size:17px;">1. Incident Type</p>
                <v-select :items="typesIncident" item-text="category" :rules="rules" v-model="type" item-value="id" style="margin-top:0px !important;"></v-select>

                <p style="margin-bottom:5px; font-size:17px;">2. Incident Date</p>
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateIncident"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="dateIncident"
                    @input="menu2 = false"
                    ></v-date-picker>
                </v-menu>

                <p style="margin-bottom:5px; font-size:17px;">3. Incident Description</p>
                <v-textarea outlined auto-grow rows="7" :rules="rules" v-model="incident" class="pb-o"></v-textarea>

                <v-file-input 
                class="pt-0 mt-0"
                v-model="upload" 
                accept="image/png, image/jpeg, image/bmp"
                color="primary"
                truncate-length="15"
                label="Add Incident Image"
                prepend-icon="mdi-camera"></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn style="text-transform: capitalize;margin-top:-30px;" class="primary" @click.once="submitIncident()">Submit</v-btn>
            </v-card-actions>
        </v-card>      
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
    name: "AddIncident",
    data: () => ({
        dateIncident: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu2: false,
        incident: "",
        type: "",
        upload:null,
        rules: [
            value => !!value || "Required."
        ],
        user_p : JSON.parse(localStorage.getItem('user'))
    }),
    computed:{
        ...mapGetters (["getCurrentProject","getIncidentTypes" ]),
        project () {
            return this.getCurrentProject
        },
        typesIncident () {
            return this.getIncidentTypes
        }
    },
    mounted(){
        this.fetchIncidentType()
    },
    methods: {
        ... mapActions (['fetchIncidentType']),
        async submitIncident () {
            let formData = new FormData();
            formData.append("type",this.type)
            formData.append("incident_date",this.dateIncident)
            formData.append("incident",this.incident)
            formData.append("project", this.project.id)
            formData.append("user", this.user_p.id)
            formData.append("upload",this.upload)
            axios.post(
                "https://camusatweb.ubuniworks.com/api/v1/incident",
                formData,
                { headers: {"Content-Type": "multipart/form-data"} }
            ).then((response) => {
                if(response.data.status == true){
                    Swal.fire({
                        icon: 'success',
                        title: 'Incident successfully submitted',
                        text: 'You will be informed once it has been reviewed'
                    })
                    this.$router.push('/incident')
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Incident submission failed',
                        text: response.data.message
                    })
                }
            })
            
        }
    }
}
</script>
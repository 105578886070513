<template>
    <v-container>
        <h3 class="black--text mb-2">{{ project.name }}</h3>
        <h3 class="black--text mb-2" style="border-bottom:1px solid #000;">Project Incident Report</h3>
        
        <v-card
        class="mt-1 pa-1"
        elevation="2"
        outlined
        v-for="incident in incidents" v-bind:key="incident.id"
        >
            <p class="pb-0 mb-0"><strong>Incident Status :</strong> <v-btn small elevation="2" :color="updateStatus(incident.status)">{{ incident.status }}</v-btn></p>
            <p class="pb-0 mb-0"><strong>Incident ID :</strong> {{ incident.incident_code }}</p>
            <p class="pb-0 mb-0"><strong>Incident Date :</strong> {{ incident.created_at }}</p>
            <p class="pb-0 mb-0"><strong>Incident Reported By :</strong> {{ incident.user.name }}</p>
            <p class="pb-0 mb-0"><strong>Incident Description :</strong></p>
            <p class="pb-0 mb-0">{{ incident.incident }}</p>
            <v-img
            :lazy-src="incident.media"
            max-height="250"
            max-width="250"
            :src="incident.media"
            ></v-img>
        </v-card>

    </v-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import date from 'date-and-time'
export default {
    name:'ViewIncident',
    data: () => ({
        now_date : "",
        user_p: JSON.parse(localStorage.getItem('user'))
    }),
    methods:{
        ... mapActions (['fetchIncident']),
        updateStatus (value){
            if(value == "reviewed"){
                return "success"
            }else{
                return "warning"
            }
        }
    },
    computed: {
        ...mapGetters (["getCurrentProject","getIncidents" ]),
        project () {
            return this.getCurrentProject
        },
        incidents () {
            return this.getIncidents
        }
    },
    mounted(){
        let now  = new Date();
        let now_dates = date.format(now, 'ddd, MMM DD YYYY');
        this.now_date = now_dates 
        this.fetchIncident(this.project.id)
    }
}
</script>